import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { Navigate } from 'react-router-dom'
import { API_URL } from 'src/config'
function AuthenticatedRoute({ children }) {
  const [cookies, removeCookie] = useCookies(['token'])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (cookies.token) {
      // Start token validation
      fetch(`${API_URL}/validate-token/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${cookies.token}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            // Token is invalid, remove it from cookies
            console.error('Token is invalid, removing it from cookies')
            removeCookie('token')
          }
          console.log('Token is valid')
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('Error validating token:', error)
          removeCookie('token')
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [cookies.token, removeCookie])

  if (isLoading) {
    return <div>Loading...</div> // or replace with a loading component
  }

  return cookies.token ? children : <Navigate to="/login" />
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticatedRoute
